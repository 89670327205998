<template>
    <div class="schoolSpace">
        <div class="schName flex-align-center">
            {{`${$route.query.county}空间`}}
        </div>
        <div class="spceType">
            <div class="location_L">
                <div class="live_lesson mt_style">
                    <div class="live_lesson_headline headline_bg">
                        <div class="font_style">各项目学习时长</div>
                    </div>
                    <div class="live_lesson_graphic" id="duration_graphic"></div>
                </div>
                <div class="record mt_style">
                    <div class="record_headline headline_bg">
                        <div class="font_style">各项目成果数量</div>
                    </div>
                    <div class="record_graphic" id="result_graphic"></div>
                </div>
            </div>
            <div class="location_C">
                <div class="classification">
                    <div class="totality_headline headline_bg">
                        <div class="font_style">全部重点数据</div>
                    </div>
                    <div class="flex-align-around">
                        <div class="classification_bg classification_img flex-column-center">
                            <div class="title">参与学校</div>
                            <div class="type_num">
                                <div class="num">{{schoolValue}}</div>
                                <!-- <div class="unit">人</div> -->
                            </div>
                        </div>
                        <div class="classification_bg classification_img flex-column-center">
                            <div class="title">参训总人数</div>
                            <div class="type_num">
                                <div class="num">{{memberValue}}</div>
                                <!-- <div class="unit">个</div> -->
                            </div>
                        </div>
                        <div class="classification_bg classification_img flex-column-center">
                            <div class="title">项目总数</div>
                            <div class="type_num">
                                <div class="num">{{projectValue}}</div>
                                <!-- <div class="unit">%</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="totality mt_style">
                    <div class="totality_headline headline_bg">
                        <div class="font_style">各学校参与人数</div>
                    </div>
                    <div class="totality_graphic" id="join_graphic"></div>
                </div>
                <div class="totality mt_style">
                    <div class="totality_headline headline_bg">
                        <div class="font_style">各项目参与人数</div>
                    </div>
                    <div class="totality_graphic_2" id="proJoin_graphic"></div>
                </div>
            </div>
            <div class="location_R">
                <div class="submit_job mt_style">
                    <div class="submit_job_headline headline_bg">
                        <div class="font_style">各时段学习情况统计</div>
                    </div>
                    <div class="submit_job_graphic" id="status_graphic"></div>
                </div>
                <div class="project">
                    <div class="project_headline headline_bg">
                        <div class="font_style">各时段任务提交情况统计</div>
                    </div>
                    <div class="project_graphic" id="submit_graphic"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        //import引入组件才能使用
        components: {},
        props: {},
        data() {
            return {
                //-----
                // 参训总人数
                memberValue: 0,
                // 项目总数量
                projectValue: '978',
                // 参与学校
                schoolValue: '15',
            };
        },
        // 计算属性
        computed: {},
        // 监听data中的数据变化
        watch: {},
        // 方法集合
        methods: {
            // 获取总数据
            async getTotalTitle() {
                let params = {
                    areaId: this.$route.query.areaId,
                }

                let resData = await this.$Api.dataScreen.getTotalTitle(params);
                // console.log('总数据', resData.data);
                this.projectValue = resData.data.projectCount;
                this.schoolValue = resData.data.schoolCount;
                this.memberValue = resData.data.userCount;
            },
            // 绘制各项目学习时长  柱状图+折线图
            async drawDurationChart() {
                var chart = document.getElementById('duration_graphic');
                var durationChart = this.$echarts.init(chart);
                let _this = this;
                window.addEventListener('resize', () => {
                    durationChart.resize()
                })

                let params = {
                    areaId: this.$route.query.areaId,
                    page: 1,
                    limit: 1000
                }

                let resData = await this.$Api.dataScreen.getStudyAll(params);
                // console.log('各项目学习时长', resData.data);
                let nameList = [];
                let areaValueList = [];
                let cityValueList = [];
                let provinceValueList = [];
                resData.data.map(item => {
                    nameList.push(item.projectName)

                    if (item.areaCount) {
                        areaValueList.push((item.areaValue / 3600 / item.areaCount).toFixed(2))
                    } else {
                        areaValueList.push(0)
                    }

                    if (item.cityCount) {
                        cityValueList.push((item.cityValue / 3600 / item.cityCount).toFixed(2))
                    } else {
                        cityValueList.push(0)
                    }

                    if (item.provinceCount) {
                        provinceValueList.push((item.provinceValue / 3600 / item.provinceCount).toFixed(2))
                    } else {
                        provinceValueList.push(0)
                    }

                })

                // console.log('areaValueList', areaValueList);
                // console.log('cityValueList', cityValueList);
                // console.log('provinceValueList', provinceValueList);
                let that = this;

                let option = {
                    tooltip: {
                        transitionDuration: 0,
                        trigger: 'item',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: function (params) {
                            // console.log(params);
                            let colorMap = {
                                // '旗县平均时长': 'rgba(41,217,255,1)',
                                '盟市平均时长': '#ffdc4c',
                                '全蒙平均时长': '#ff984d',
                            }
                            colorMap[`${that.$route.query.county}平均时长`] = 'rgba(41,217,255,1)'
                            let dot =
                                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:${colorMap[params.seriesName]};\"></span>`
                            let str =
                                `${params.seriesName}  </br>${dot} ${params.name}: ${params.value} 小时`;
                            return str;
                        },
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                        top: '25%',
                        containLabel: true
                    },
                    legend: {
                        data: [`${that.$route.query.county}平均时长`, '盟市平均时长', '全蒙平均时长'],
                        left: '3%',
                        top: '6%',
                        icon: 'circle',
                        textStyle: {
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 16,
                        },
                        itemWidth: 16,
                        itemHeight: 16,
                        itemGap: 25
                    },
                    dataZoom: {
                        startValue: 0,
                        endValue: 5,
                        type: "inside",
                        // zoomLock: true,
                        // filterMode: "weakFilter"
                    },
                    xAxis: {
                        type: 'category',
                        data: nameList,
                        axisLine: {
                            lineStyle: {
                                color: '#4d4d4d'
                            }
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            // interval: 1,
                            margin: 10,
                            align: "center",
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            },
                            formatter: function (value, index) {
                                if (value.length > 4) {
                                    return `${value.substring(0, 4)}...`
                                } else {
                                    return value;
                                }

                            }
                        },
                    },

                    yAxis: [{
                            type: 'value',
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#cdd5e2'
                                }
                            },
                            splitLine: {
                                // show: false,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#cdd5e2'
                                }
                            },
                            axisLabel: {
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 14,
                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#4d4d4d'
                                }
                            },
                        },
                        {
                            type: "value",
                            // name: "百分比",
                            splitNumber: 2,
                            nameTextStyle: {
                                color: "#fff"
                            },
                            position: "right",
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#4d4d4d'
                                }
                            },
                            splitLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                show: true,
                                // formatter: "{value} %", //右侧Y轴文字显示
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 14,
                                }
                            }
                        }
                    ],
                    series: [{
                            name: `${that.$route.query.county}平均时长`,
                            type: 'bar',
                            barWidth: '20px',
                            itemStyle: {
                                normal: {
                                    color: new _this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                            offset: .2,
                                            color: 'rgba(0,89,235,.1)'
                                        },
                                        {
                                            offset: .5,
                                            color: 'rgba(0,89,235,.8)'
                                        },
                                        {
                                            offset: 0.8,
                                            color: 'rgba(41,217,255,1)'
                                        },

                                    ]),
                                }

                            },
                            data: areaValueList
                        },
                        {
                            name: "盟市平均时长",
                            type: "line",
                            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                            smooth: false, //平滑曲线显示

                            symbol: "circle", //标记的图形为实心圆
                            symbolSize: 8, //标记的大小
                            itemStyle: {
                                normal: {
                                    color: '#ffdc4c',
                                    borderColor: '#fff', //圆点透明 边框
                                    borderWidth: 2
                                },

                            },
                            lineStyle: {
                                color: "#ffdc4c"
                            },
                            smooth: true,
                            data: cityValueList
                        }, {
                            name: "全蒙平均时长",
                            type: "line",
                            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                            smooth: false, //平滑曲线显示

                            symbol: "circle", //标记的图形为实心圆
                            symbolSize: 8, //标记的大小
                            itemStyle: {
                                normal: {
                                    color: '#ff984d',
                                    borderColor: '#fff', //圆点透明 边框
                                    borderWidth: 2
                                },

                            },
                            lineStyle: {
                                color: "#ff984d"
                            },
                            smooth: true,
                            data: provinceValueList
                        }
                    ]
                };

                durationChart.setOption(option);
            },
            // 绘制各项目成果数量  柱状图+折线图 横向
            async drawResultChart() {
                var chart = document.getElementById('result_graphic');
                var resultChart = this.$echarts.init(chart);
                let _this = this;
                window.addEventListener('resize', () => {
                    resultChart.resize()
                })

                let params = {
                    areaId: this.$route.query.areaId,
                    page: 1,
                    limit: 1000
                }

                let resData = await this.$Api.dataScreen.getAchievement(params);
                // console.log('各项目成果数量', resData.data);
                let nameList = [];
                let areaValueList = [];
                let cityValueList = [];
                let provinceValueList = [];
                resData.data.map(item => {
                    nameList.push(item.projectName)
                    if (item.areaCount) {
                        areaValueList.push((item.areaValue / item.areaCount).toFixed(2))
                    } else {
                        areaValueList.push(0)
                    }

                    if (item.cityCount) {
                        cityValueList.push((item.cityValue / item.cityCount).toFixed(2))
                    } else {
                        cityValueList.push(0)
                    }

                    if (item.provinceCount) {
                        provinceValueList.push((item.provinceValue / item.provinceCount).toFixed(2))
                    } else {
                        provinceValueList.push(0)
                    }



                })

                let that = this;
                let option = {
                    tooltip: {
                        transitionDuration: 0,
                        trigger: 'item',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: function (params) {
                            // console.log(params);
                            let colorMap = {
                                // '旗县平均时长': '#e35a54',
                                '盟市平均数量': '#e79339',
                                '全蒙平均数量': '#280af4',
                            }
                            colorMap[`${that.$route.query.county}平均数量`] = '#e35a54'
                            let dot =
                                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:${colorMap[params.seriesName]};\"></span>`
                            let str =
                                `${params.seriesName}  </br>${dot} ${params.name}: ${params.value} 个`;
                            console.log(str);
                            return str;
                        },
                    },
                    grid: {
                        left: '5%',
                        right: '10%',
                        bottom: '5%',
                        top: '20%',
                        containLabel: true
                    },
                    legend: {
                        data: [`${this.$route.query.county}平均数量`, '盟市平均数量', '全蒙平均数量'],
                        left: '3%',
                        top: '6%',
                        icon: 'circle',
                        textStyle: {
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 16,
                        },
                        itemWidth: 16,
                        itemHeight: 16,
                        itemGap: 25
                    },

                    dataZoom: {
                        startValue: 0,
                        endValue: 5,
                        type: "inside",
                        // zoomLock: true,
                        orient: "vertical"
                        // filterMode: "weakFilter"
                    },
                    xAxis: {
                        type: 'value',

                        axisLine: {
                            lineStyle: {
                                color: '#4d4d4d'
                            }
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            margin: 10,
                            align: "center",
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            }
                        },
                    },

                    yAxis: [{
                        type: "category",
                        inverse: true,
                        data: nameList,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#cdd5e2'
                            }
                        },
                        splitLine: {
                            // show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#cdd5e2'
                            }
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            },
                            formatter: function (value, index) {
                                if (value.length > 5) {
                                    return `${value.substring(0, 5)}...`
                                } else {
                                    return value;
                                }

                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#4d4d4d'
                            }
                        },
                    }],
                    series: [{
                            name: `${this.$route.query.county}平均数量`,
                            type: "line",
                            // yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                            smooth: false, //平滑曲线显示

                            symbol: "circle", //标记的图形为实心圆
                            symbolSize: 8, //标记的大小
                            itemStyle: {
                                normal: {
                                    color: '#e35a54',
                                    borderColor: '#fff', //圆点透明 边框
                                    borderWidth: 1
                                },

                            },
                            lineStyle: {
                                color: "#e35a54"
                            },
                            smooth: true,
                            data: areaValueList
                        },
                        {
                            name: '盟市平均数量',
                            type: 'bar',
                            barWidth: '12px',
                            itemStyle: {
                                normal: {
                                    color: new _this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: '#e79339'
                                        },
                                        {
                                            offset: 1,
                                            color: '#3b6aba'
                                        },

                                    ]),
                                }

                            },
                            data: cityValueList
                        },
                        {
                            name: '全蒙平均数量',
                            type: 'bar',
                            barWidth: '12px',
                            itemStyle: {
                                normal: {
                                    color: new _this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                            offset: 0,
                                            color: '#24bfdb'
                                        },
                                        {
                                            offset: 1,
                                            color: '#280af4'
                                        },

                                    ]),
                                }

                            },
                            data: provinceValueList
                        },

                    ]
                };

                resultChart.setOption(option);
            },
            // 绘制各学校参与人数 阶梯瀑布图 （本质柱状图）
            async drawJoinChart() {
                var chart = document.getElementById('join_graphic');
                var joinChart = this.$echarts.init(chart);
                let _this = this;

                let params = {
                    areaId: this.$route.query.areaId,
                    page: 1,
                    limit: 1000,
                }

                let resData = await this.$Api.dataScreen.getSchoolUserCount(params);
                // console.log('各学校参与人数', resData.data.records);
                let schoolNameList = ['总人数'];
                let schoolNumberList = [];
                let helpList = [0];
                let sum = resData.data.userCount;

                resData.data.statChartEntityPage.records.map(item => {
                    schoolNameList.push(item.projectName || '-');
                    schoolNumberList.push(item.count);
                })
                let sumList = [];
                for (let i = 0; i < schoolNameList.length; i++) {
                    if (i == 0) {
                        sumList.push(sum);
                    } else {
                        sumList.push('-')
                    }
                }

                let helpCounter = 0
                schoolNumberList.map(item => {
                    // console.log(item)
                    helpCounter += item
                    helpList.push(sum - helpCounter);
                })

                // console.log('helpList', helpList)

                schoolNumberList.unshift('-')



                window.addEventListener('resize', () => {
                    joinChart.resize()
                })

                let option = {
                    tooltip: {
                        transitionDuration: 0,
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'none' // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: function (params) {
                            // console.log(params);
                            var tar;
                            if (params[1].value != '-') {
                                tar = params[1];
                            } else {
                                tar = params[2];
                            }
                            return tar.name + '<br/>' + tar.marker + tar.seriesName + ' : ' + tar.value;
                        }
                    },
                    grid: {
                        left: '4%',
                        right: '4%',
                        bottom: '3%',
                        top: '15%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#999999',
                            }
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            margin: 10,
                            align: "center",
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            },
                            formatter: function (value, index) {
                                if (value.length > 4) {
                                    return `${value.substring(0, 4)}...`
                                } else {
                                    return value;
                                }

                            }
                        },
                        data: schoolNameList,
                    },
                    yAxis: [{
                            type: 'value',
                            axisLine: {
                                show: false,
                            },
                            splitLine: {
                                lineStyle: {
                                    color: '#999999',
                                    type: 'dashed',
                                }
                            },
                            axisLabel: {
                                show: false,
                            }

                        },
                        {
                            type: "value",
                            name: "单位：人",
                            offset: -45,
                            nameTextStyle: {
                                color: "#fff",
                                fontSize: 14,
                                verticalAlign: "top",
                            },
                            axisLine: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            }

                        }
                    ],
                    series: [{
                            name: '辅助',
                            type: 'bar',
                            stack: '总量',
                            itemStyle: {
                                normal: {
                                    barBorderColor: 'rgba(0,0,0,0)',
                                    color: 'rgba(0,0,0,0)'
                                },
                                emphasis: {
                                    barBorderColor: 'rgba(0,0,0,0)',
                                    color: 'rgba(0,0,0,0)'
                                }
                            },
                            emphasis: {
                                disabled: true
                            },
                            data: helpList
                        },
                        {
                            name: '总人数',
                            type: 'bar',
                            stack: '总量',
                            barWidth: '30px',
                            label: {
                                show: true,
                                position: 'top',
                                color: "#fff",
                                fontSize: '15px'
                            },
                            itemStyle: {
                                normal: {
                                    barBorderRadius: 2,
                                    color: '#2d8bfd',
                                }

                            },
                            data: sumList
                        },
                        {
                            name: '参与人数',
                            type: 'bar',
                            stack: '总量',
                            barWidth: '30px',
                            label: {
                                show: true,
                                position: 'top',
                                color: "#fff",
                                fontSize: '15px'
                            },
                            itemStyle: {
                                normal: {
                                    barBorderRadius: 2,
                                    color: '#4fe3c1'
                                }

                            },
                            data: schoolNumberList
                        }
                    ]
                };

                joinChart.setOption(option);
            },
            // 绘制各项目参与人数  折线图 
            async drawProJoinChart() {
                var chart = document.getElementById('proJoin_graphic');
                var proJoinChart = this.$echarts.init(chart);
                let _this = this;
                window.addEventListener('resize', () => {
                    proJoinChart.resize()
                })


                let params = {
                    areaId: this.$route.query.areaId,
                    page: 1,
                    limit: 1000,
                }

                let resData = await this.$Api.dataScreen.getProjectUserCount(params);
                // console.log('各项目参与人数', resData.data.records);
                let projectNameList = [];
                let preojectNumberList = [];
                resData.data.records.map(item => {
                    projectNameList.push(item.projectName);
                    preojectNumberList.push(item.count);
                })


                let option = {
                    tooltip: {
                        transitionDuration: 0,
                        trigger: 'axis',
                        formatter: function (params) {
                            // console.log(params);
                            let el = params[0]
                            let dot =
                                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(85,210,216, 1);\"></span>`
                            return `${el.name}<br> ${dot} ${el.seriesName}: ${el.value} 人`
                        },
                    },
                    grid: {
                        left: '12%',
                        right: '12%',
                        top: '15%',
                        bottom: "23%",
                    },
                    xAxis: [{
                        type: 'category',
                        data: projectNameList,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            // interval: 1,
                            margin: 10,
                            color: '#a3d7ff',
                            fontSize: 16,
                            formatter: function (value, index) {
                                if (value.length > 4) {
                                    return `${value.substring(0, 4)}...`
                                } else {
                                    return value;
                                }

                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        // boundaryGap: false
                    }],
                    yAxis: [{
                        type: 'value',
                        splitNumber: 2,
                        splitLine: {
                            show: [false, true, true],
                            lineStyle: {
                                type: 'dashed',
                                color: ['rgba(0,0,0,0)', '#ddd', '#ddd']
                            }
                        },
                        axisLabel: {
                            color: '#DDD',
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitArea: {
                            show: false
                        }
                    }],
                    dataZoom: {
                        startValue: 0,
                        endValue: 10,
                        type: "inside",
                        // zoomLock: true,
                        filterMode: "weakFilter"
                    },
                    series: [{
                        name: '参与人数',
                        type: 'line',
                        data: preojectNumberList,
                        lineStyle: {
                            normal: {
                                width: 3,
                                color: {
                                    type: 'linear',
                                    colorStops: [{
                                        offset: 1,
                                        color: '#60effd' // 0% 处的颜色
                                    }, ],
                                    globalCoord: false // 缺省为 false
                                },
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new _this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: 'rgba(85,210,216, 1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(42,120,218, 1)'
                                }], false),
                                // shadowBlur: 10
                            }
                        },
                        itemStyle: {
                            normal: {
                                opacity: 0,
                            }
                        },
                        smooth: true
                    }]
                };

                proJoinChart.setOption(option)
            },
            // 绘制各时段学习情况统计  柱状图+折线图
            async drawStatusChart() {
                var chart = document.getElementById('status_graphic');
                var statusChart = this.$echarts.init(chart);
                let _this = this;


                window.addEventListener('resize', () => {
                    statusChart.resize()
                })

                let params = {
                    areaId: this.$route.query.areaId,
                }

                let resData = await this.$Api.dataScreen.getTimeStudy(params);
                // console.log('各时段学习情况', resData.data);
                let studyTimeList = [];
                let countSum = 0
                let citySum = 0
                let provinceSum = 0
                resData.data.map(item => {
                    studyTimeList.push(item.time)
                    countSum += item.count
                    citySum += item.cityCount
                    provinceSum += item.provinceCount
                })

                let counterList = [];
                let cityCounterList = [];
                let provincecounterList = [];

                resData.data.map(item => {
                    if (countSum) {
                        counterList.push((item.count / countSum * 100).toFixed(2))
                    } else {
                        counterList.push(0)
                    }

                    if (citySum) {
                        cityCounterList.push((item.cityCount / citySum * 100).toFixed(2))
                    } else {
                        cityCounterList.push(0)
                    }

                    if (provinceSum) {
                        provincecounterList.push((item.provinceCount / provinceSum * 100).toFixed(2))
                    } else {
                        provincecounterList.push(0)
                    }



                })
                // console.log('studyList', counterList)



                let option = {
                    tooltip: {
                        transitionDuration: 0,
                        // trigger: 'item',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: function (params) {
                            let color = params.seriesName == "全蒙" ? 'rgba(120,20,216,.6)' :
                                'linear-gradient(rgba(79,104,186,1),rgba(189,55,80,1) )'
                            let dot =
                                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:${color};\"></span>`
                            let str =
                                `${params.seriesName}  </br>${dot} ${params.name}: ${params.value} %`;
                            return str;
                        },
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                        top: '25%',
                        containLabel: true
                    },
                    legend: {
                        data: [`${this.$route.query.county}`, '盟市', '全蒙'],
                        left: '3%',
                        top: '12%',
                        icon: 'circle',
                        textStyle: {
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 16,
                        },
                        itemWidth: 16,
                        itemHeight: 16,
                        itemGap: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12', '12-14', '14-16', '16-18', '18-20',
                            '20-22', '22-24'
                        ],
                        axisLine: {
                            lineStyle: {
                                color: '#4d4d4d'
                            }
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            interval: 0,
                            rotate: 45,
                            margin: 35,
                            align: "center",
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            }
                        },
                    },

                    yAxis: [{
                            type: 'value',
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#cdd5e2'
                                }
                            },
                            splitLine: {
                                // show: false,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#cdd5e2'
                                }
                            },
                            axisLabel: {
                                formatter: "{value} %", //右侧Y轴文字
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 14,
                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#4d4d4d'
                                }
                            },
                        },
                        {
                            type: "value",
                            // name: "百分比",
                            splitNumber: 3,
                            nameTextStyle: {
                                color: "#fff"
                            },
                            position: "right",
                            axisLine: {
                                lineStyle: {
                                    color: '#4d4d4d'
                                }
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: true,
                                formatter: "{value} %", //右侧Y轴文字显示
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 14,
                                }
                            }
                        }
                    ],
                    series: [{
                            name: '盟市',
                            type: 'bar',
                            barWidth: '12px',
                            itemStyle: {
                                normal: {
                                    color: new _this.$echarts.graphic.LinearGradient(0, 1, 1, 0, [{
                                            offset: 0.1,
                                            color: 'rgba(189,55,80,1)'
                                        },
                                        {
                                            offset: 0.3,
                                            color: 'rgba(202,98,73,1)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(79,104,186,1)'
                                        },

                                    ]),
                                }

                            },
                            data: cityCounterList
                        }, {
                            name: '全蒙',
                            type: 'bar',
                            barWidth: '12px',
                            barGap: "12%",
                            itemStyle: {
                                normal: {
                                    color: new _this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: 'rgba(120,20,216,.6)'
                                    }, {
                                        offset: 1,
                                        color: 'rgba(120,20,216,.6)'
                                    }]),
                                },
                            },
                            data: provincecounterList
                        },

                        {
                            name: `${this.$route.query.county}`,
                            type: "line",
                            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                            smooth: false, //平滑曲线显示

                            symbol: "circle", //标记的图形为实心圆
                            symbolSize: 8, //标记的大小
                            itemStyle: {
                                normal: {
                                    color: '#5aaef4',
                                    borderColor: '#fff', //圆点透明 边框
                                    borderWidth: 2
                                },

                            },
                            lineStyle: {
                                color: "#5aaef4"
                            },

                            data: counterList
                        }
                    ]
                };

                statusChart.setOption(option);
            },
            // 绘制各时段提交情况统计  折线图
            async drawSubmitChart() {
                var chart = document.getElementById('submit_graphic');
                var submitChart = this.$echarts.init(chart);
                let _this = this;
                window.addEventListener('resize', () => {
                    submitChart.resize()
                })


                let params = {
                    areaId: this.$route.query.areaId,
                    // page: 1,
                    // limit: 1000,
                }

                let resData = await this.$Api.dataScreen.getTimeQuest(params);
                // console.log('各时段作业情况', resData.data);
                let workTimeList = [];
                let workNumberList = [];
                let sum = 0;
                resData.data.map(item => {
                    sum += item.count;
                    workTimeList.push(item.time);
                    workNumberList.push(item.count);
                })
                let counterList = [];
                workNumberList.map(item => {
                    counterList.push((item / sum * 100).toFixed(2))
                })

                // console.log('counterList', counterList)




                let option = {
                    tooltip: {
                        transitionDuration: 0,
                        trigger: 'axis',
                        formatter: "{a}<br>{b}：{c} %"
                    },
                    grid: {
                        top: '20%',
                        bottom: "10%",
                    },
                    xAxis: [{
                        type: 'category',
                        data: ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12', '12-14', '14-16', '16-18',
                            '18-20', '20-22', '22-24'
                        ],
                        axisLine: {
                            lineStyle: {
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            interval: 1
                        },
                        boundaryGap: false
                    }],
                    yAxis: [{
                        type: 'value',
                        splitNumber: 4,
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: '#DDD'
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#DDD'
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitArea: {
                            show: false
                        },
                        axisLabel: {
                            show: true,
                            formatter: "{value} %", //右侧Y轴文字显示
                        }
                    }],
                    series: [{
                        name: '提交情况',
                        type: 'line',
                        data: counterList,
                        lineStyle: {
                            normal: {
                                width: 3,
                                color: {
                                    type: 'linear',

                                    colorStops: [{
                                        offset: 0,
                                        color: 'rgba(167,223,250, 1)' // 0% 处的颜色
                                    }, {
                                        offset: .5,
                                        color: 'rgba(226,222,159, 1)' // 50% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: 'rgba(231,98,89, 1)' // 100% 处的颜色
                                    }],
                                    globalCoord: false // 缺省为 false
                                },
                                // shadowColor: 'rgba(72,216,191, 0.3)',
                                // shadowBlur: 10,
                                // shadowOffsetY: 20
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new _this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: 'rgba(167,223,250, .5)'
                                }, {
                                    offset: 0.5,
                                    color: 'rgba(226,222,159, .5)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(231,98,89, .5)'
                                }], false),
                                // shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        itemStyle: {
                            normal: {
                                opacity: 0,
                                color: '#fff',
                                borderWidth: 10,
                                /*shadowColor: 'rgba(72,216,191, 0.3)',
                                shadowBlur: 100,*/
                                borderColor: '#A9F387'
                            }
                        },
                        smooth: true
                    }]
                };

                submitChart.setOption(option)
            }
        },
        // 生命周期，创建完成时（可以访问当前this实例）
        created() {

        },
        // 生命周期：挂载完成时（可以访问DOM元素）
        mounted() {
            this.$nextTick(() => {
                this.getTotalTitle();
                this.drawDurationChart();
                this.drawResultChart();
                this.drawJoinChart();
                this.drawProJoinChart();
                this.drawStatusChart();
                this.drawSubmitChart();
            })
        },
        beforeCreate() {}, //生命周期：创建之前
        beforeMount() {}, //生命周期：挂载之前
        beforeUpdate() {}, //生命周期：更新之前
        updated() {}, //生命周期：更新之后
        beforeDestroy() {}, //生命周期：销毁之前
        destroyed() {}, //生命周期：销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发执行
    }
</script>
<style scoped lang="less">
    @import "./Less/county.less";
</style>